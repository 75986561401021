import cn from "classnames";
import { Highlight, useHits } from "react-instantsearch";

import Button from "../../atoms/button/Button";
import { useCountry } from "../../func-core/core/application/hooks/useCountry";
import * as styles from "./BreedSelect.module.scss";

interface BreedHitsProps {
  selectHit: (uuid, name) => void;
  breedName: string | null | undefined;
}

const BreedHits = ({ selectHit, breedName }: BreedHitsProps): JSX.Element => {
  const { hits } = useHits();
  const { country } = useCountry();

  const language = country.language.code.split("-")[0].toLowerCase();
  const handleClick = (hit): void => {
    selectHit(hit.uuid_key, hit[`name_${language}`]);
  };

  return (
    <section className={styles.hitsList}>
      {hits.map((hit) => {
        const isSelectedHit = hit[`name_${language}`] === breedName;

        const matchesBySynonym = (
          hit._highlightResult?.[`synonyms_${language}`] as { matchLevel?: string }
        )?.matchLevel?.match(/full|partial/);

        return (
          <Button
            key={hit.objectID}
            type="button"
            className={cn(styles.hit, { [styles.isSelectedHit]: isSelectedHit })}
            onClick={() => handleClick(hit)}
          >
            <div>
              <Highlight attribute={`name_${language}`} hit={hit} />
              {matchesBySynonym && <Highlight attribute={`synonyms_${language}`} hit={hit} />}
            </div>
            <svg className="icon icon--check" role="img" aria-hidden="true">
              <use xlinkHref="#icon-white-check"></use>
            </svg>
          </Button>
        );
      })}
    </section>
  );
};

export default BreedHits;
