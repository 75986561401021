import algoliasearch from "algoliasearch/lite";
import cn from "classnames";
import { useRef, useState } from "react";
import { Configure, InstantSearch } from "react-instantsearch";

import { useCountry } from "../../func-core/core/application/hooks/useCountry";
import { getWindow } from "../../utils/browser-features";
import BreedHits from "./BreedHits";
import BreedSearchBox from "./BreedSearchBox";
import * as styles from "./BreedSelect.module.scss";

const gastbyAlgoliaAppId = process.env.GATSBY_ALGOLIA_APP_ID ?? "";
const gastbyAlgoliaSearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? "";
const searchClient = algoliasearch(gastbyAlgoliaAppId, gastbyAlgoliaSearchKey);

interface BreedSelectProps {
  species: string;
  selectBreedUuid: (uuid: string) => void;
  adoptionClassName?: string;
  preselectedBreedName?: string | null | undefined;
}

const BreedSelect = ({
  species,
  selectBreedUuid,
  adoptionClassName,
  preselectedBreedName,
}: BreedSelectProps): JSX.Element => {
  const [breedName, setBreedName] = useState(preselectedBreedName ?? "");
  const ref = useRef<HTMLDivElement>(null);
  const { country } = useCountry();

  const language = country.language.code.split("-")[0].toLowerCase();
  const nameAttribute = `name_${language}`;
  const synonymsAttribute = `synonyms_${language}`;
  const searchableAttributes = [nameAttribute, synonymsAttribute].filter(
    (attr) => attr in searchClient.initIndex("breeds")
  );

  const storeBreedNameInCache = (name: string): void => {
    getWindow()?.sessionStorage?.setItem("breedName", name);
  };

  const selectHit = (uuid: string, name: string): void => {
    selectBreedUuid(uuid);
    storeBreedNameInCache(name);
    setBreedName(name);
  };

  const toggleBreedHits = (): void => {
    ref.current?.classList.toggle("isBreedHitsOpen");
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={`breeds`}>
      <Configure
        filters={`species:${species}`}
        restrictSearchableAttributes={searchableAttributes}
        attributesToRetrieve={[`name_${language}`, `synonyms_${language}`, "uuid_key"]}
      />
      <div ref={ref} className={cn(styles.breedSelect, adoptionClassName)}>
        <BreedSearchBox breedName={breedName} toggleBreedHits={toggleBreedHits} />
        <BreedHits breedName={breedName} selectHit={selectHit} />
      </div>
    </InstantSearch>
  );
};

export default BreedSelect;
